// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---content-posts-hello-mdx": () => import("./../content/posts/hello.mdx" /* webpackChunkName: "component---content-posts-hello-mdx" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-post-jsx": () => import("./../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */)
}

